import AdminRoutes from '@/router/routes/AdminRoutes';
import Auth from '@/router/routes/Auth';
import SurveyDashboardRoutes from '@/router/routes/SurveyDashboard/SurveyDashboardRoutes';
import SurveyPreviewRoutes from '@/router/routes/SurveyPreviewRoutes';
import SurveyRoutes from '@/router/routes/SurveyRoutes';
import UserDashboardRoutes from '@/router/routes/UserDashboardRoutes';
import { RouteRecordRaw } from 'vue-router';
import PreviewResultsRoute from './routes/SurveyDashboard/Results/PreviewResultsRoute';
import WorkspaceInviteRoutes from './routes/WorkspaceInviteRoutes';

const routes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/views/Auth/AuthHome.vue'),
    children: Auth,
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/Welcome/WelcomePageSlides.vue'),
    meta: {
      authorize: true,
      user: true,
    },
  },
  {
    path: '/users/verify-email',
    name: 'verify-email',
    component: () => import('@/views/Users/VerifyEmail.vue'),
    meta: {
      user: true,
    },
  },
  {
    path: '/workspace-setup/:workspaceId',
    name: 'workspace-setup',
    component: () =>
      import('@/views/WorkspaceSetup/WorkspaceSetupPageSlides.vue'),
    meta: {
      authorize: true,
      user: true,
    },
    props: true,
  },
  {
    path: '/workspace-invite/:workspaceShareableLinkId',
    name: 'workspace-invite',
    component: () => import('@/views/WorkspaceInvite/WorkspaceInvite.vue'),
    props: true,
    children: WorkspaceInviteRoutes,
    meta: {
      workspaceInvite: true,
    },
  },
  {
    path: '/workspaces/:workspaceId/domains/:domainId/validate',
    name: 'domain-validation',
    component: () =>
      import('@/views/WorkspaceSetup/WorkspaceDomainValidation.vue'),
    meta: {
      authorize: false,
    },
    props: true,
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/surveys',
    component: () => import('@/views/UserDashboard/UserDashboard.vue'),
    meta: {
      authorize: true,
      user: true,
    },
    children: UserDashboardRoutes,
  },
  {
    path: '/pr/:id',
    name: 'preview results container',
    redirect: { name: 'preview-results' },
    component: () => import('@/views/SurveyDashboard/SurveyDashboardHome.vue'),
    meta: {
      user: true,
      shareableLink: true,
      keepQueryParams: true,
    },
    children: [PreviewResultsRoute],
  },
  {
    path: '/survey/:id',
    component: () => import('@/views/SurveyDashboard/SurveyDashboardHome.vue'),
    meta: {
      authorize: true,
      user: true,
      keepQueryParams: true,
    },
    children: SurveyDashboardRoutes,
  },
  {
    path: '/admin',
    redirect: '/admin/surveys',
    component: () => import('@/views/Admin/AdminDashboard.vue'),
    children: AdminRoutes,
    meta: { admin: true },
  },
  {
    path: '/404',
    name: 'not-found',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: '/preview/:id',
    redirect: { name: 'preview-intro' },
    component: () => import('@/views/Survey/SurveyPage.vue'),
    meta: {
      authorize: true,
      user: true,
      preview: true,
    },
    children: SurveyPreviewRoutes,
  },
  {
    path: '/:id',
    redirect: (to) => ({ name: 'intro', query: to.query }),
    component: () => import('@/views/Survey/SurveyPage.vue'),
    meta: {
      participant: true,
    },
    children: SurveyRoutes,
  },
  { path: '/:pathMatch(.*)*', redirect: '/404' },
];

export default routes;
