import { isProduction, isTest } from '@/helpers/config';
import { useUserStore } from '@/stores/user';
import { Replay } from '@sentry/replay';
import {
  init,
  BrowserTracing,
  vueRouterInstrumentation,
  showReportDialog,
} from '@sentry/vue';

const suppressedErrors = [
  'ResizeObserver loop completed with undelivered notifications',
];

export function loadSentry(app: any, router: any) {
  if (isTest) {
    return;
  }
  init({
    app,
    dsn: 'https://63b313eb9e2d47e2845025bf8f685756@o1190876.ingest.sentry.io/6312087',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost:4505',
          ...(import.meta.env.VITE_SERVER_HOST
            ? [import.meta.env.VITE_SERVER_HOST]
            : []),
        ],
      }),
      new Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    beforeSend(event) {
      if (event.exception) {
        if (
          event.exception?.values?.some(
            (value) =>
              value.type === 'Error' &&
              suppressedErrors.some((suppressedError) =>
                value.value?.includes(suppressedError),
              ),
          )
        ) {
          return event;
        }
        const userStore = useUserStore();
        if (
          !isProduction ||
          !userStore.sentryErrorLastShown ||
          new Date().getTime() -
            new Date(userStore.sentryErrorLastShown).getTime() >
            1000 * 60 * 5
        ) {
          userStore.sentryErrorLastShown = new Date();
          showReportDialog({
            eventId: event.event_id,
            user: { email: userStore.email ?? undefined },
          });
        }
      }
      return event;
    },
    replaysSessionSampleRate: isProduction ? 0.3 : 0,
    replaysOnErrorSampleRate: isProduction ? 1 : 0,
    debug: false,
    release: VITE_APP_VERSION,
    environment: import.meta.env.MODE,
    tracesSampleRate: isProduction ? 0.3 : 0,
  });
}
